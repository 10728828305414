import * as React from 'react';

const SvgCustomBangladesh = (props) => (
	<svg
		viewBox='0 0 512 512'
		style={{
			enableBackground: 'new 0 0 512 512',
			borderRadius: '5px',
		}}
		height='1em'
		width='1em'
		xmlns='http://www.w3.org/2000/svg'
		className='svg-icon'
		{...props}>
		<path d='M1.37 20.22h510v466h-510z' fill='#006a4e' />
		<circle cx={230.87} cy={256.22} fill='#f42a41' r={102} />
	</svg>
);

export default SvgCustomBangladesh;
