export const ROLEID = {
	ADMIN:1,
	MANAGER:2
}
export const primaryChemistMenu = {
	settings: {
		id: 'settings',
		text: 'Settings',
		permission:[ROLEID.ADMIN]
	},
	product: {
		id: 'product',
		text: 'Products',
		path: '/products',
		icon: 'CardGiftcard',
		subMenu: null,
		permission:[ROLEID.ADMIN]
	},
	manageProducts: {
		id: 'manage_product',
		text: 'Manage Products',
		path: '/manage_products',
		icon: 'AppRegistration',
		subMenu: null,
		permission:[ROLEID.ADMIN]
	},
	doctors: {
		id: 'doctor',
		text: 'Doctors',
		path: '/doctors',
		icon: 'GroupAdd',
		permission:[ROLEID.ADMIN]
	},
	users: {
		id: 'userManagement',
		text: 'Users',
		path: '/users',
		icon: 'Group',
		permission:[ROLEID.ADMIN]
	},
	warehouses: {
		id: 'warehouses',
		text: 'Medicine Depots',
		path: '/warehouses',
		icon: 'MapsHomeWork',
		permission:[ROLEID.ADMIN]
	},
	pharmacies: {
		id: 'pharmacies',
		text: 'Pharmacies',
		path: '/pharmacies',
		icon: 'Medication',
		permission:[ROLEID.ADMIN]
	},
};

export const chemistMenuGroupOverview = {
	chemistMenuGroupOverview: {
		id: 'Overview',
		text: 'Overview',
		permission:[ROLEID.ADMIN,ROLEID.MANAGER]
	},
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
		permission:[ROLEID.ADMIN,ROLEID.MANAGER]
	},
	survey: {
		id: 'survey',
		text: 'Performance Survey',
		path: '/survey',
		icon: 'InsertChart',
		subMenu: null,
		permission:[ROLEID.ADMIN]
	},
	coverage: {
		id: 'coverage',
		text: 'Market Coverage',
		path: '/coverage',
		icon: 'LocalConvenienceStore',
		subMenu: null,
		permission:[ROLEID.ADMIN]
	},
	data: {
		id: 'data',
		text: 'Data',
		path: '/data',
		icon: 'Leaderboard',
		permission:[ROLEID.ADMIN,ROLEID.MANAGER],
		subMenu: {
			target_and_achievement: {
				id: 'target_and_achievement',
				text: 'Target & Achievement',
				path: 'data/target-and-achievement',
				icon: 'TrackChanges',
				permission:[ROLEID.ADMIN]
			},
			depot_Revenue_collection: {
				id: 'depot_Revenue_collection',
				text: 'Depot Revenue Collection',
				path: 'data/depot-revenue-collection',
				icon: 'CollectionsBookmark',
				permission:[ROLEID.ADMIN]
			},
			sales: {
				id: 'sales',
				text: 'Sales',
				path: 'data/sales',
				icon: 'Loyalty',
				permission:[ROLEID.ADMIN]
			},
			prescriptions: {
				id: 'prescriptions',
				text: 'Prescriptions',
				path: 'data/prescriptions',
				icon: 'Article',
				permission:[ROLEID.ADMIN,ROLEID.MANAGER]
			},
			doctor_visit: {
				id: 'doctorVisit',
				text: 'Doctor Visit',
				path: 'data/doctor-visit',
				icon: 'MedicalServices',
				permission:[ROLEID.ADMIN,ROLEID.MANAGER]
			},
			entity: {
				id: 'entity',
				text: 'Entity',
				path: 'data/entity',
				icon: 'PermIdentity',
				permission:[ROLEID.ADMIN,ROLEID.MANAGER]
			},
			reportingData: {
				id: 'reportingdata',
				text: 'Reporting Data',
				path: 'data/reporting-data',
				icon: 'PermIdentity',
				permission:[ROLEID.ADMIN]
			},
			liveData: {
				id: 'liveTV',
				text: 'Live TV',
				path: '/live_tv',
				icon: 'LiveTv',
				permission:[ROLEID.ADMIN]
			},
		},
	},
	reporting: {
		id: 'reporting',
		text: 'Reporting',
		path: '/reporting',
		icon: 'Summarize',
		permission:[ROLEID.ADMIN,ROLEID.MANAGER],
		subMenu: {
			product_wise_prescription: {
				id: 'product_wise_prescription',
				text: 'Product wise Prescription',
				path: 'reporting/product_wise_prescription',
				icon: 'TrackChanges',
				permission:[ROLEID.ADMIN]
			},
			product_and_depot_wise_prescription_report: {
				id: 'product_and_depot_wise_prescription_report',
				text: 'Product and Depot Wise Prescription',
				path: 'reporting/product_and_depot_wise_prescription_report',
				icon: 'CollectionsBookmark',
				permission:[ROLEID.ADMIN]
			},
			prescription_target: {
				id: 'prescription_target',
				text: 'Prescription Target',
				path: 'reporting/prescription_target',
				icon: 'Loyalty',
				permission:[ROLEID.ADMIN,ROLEID.MANAGER]
			},
			visit_target: {
				id: 'visit_target',
				text: 'Visit Target',
				path: 'reporting/visit_target',
				icon: 'Article',
				permission:[ROLEID.ADMIN,ROLEID.MANAGER]
			},
			requisition_target: {
				id: 'requisition_target',
				text: 'Requisition Target',
				path: 'reporting/requisition_target',
				icon: 'Announcement',
				permission:[ROLEID.ADMIN]
			},
			top_products: {
				id: 'top_products',
				text: 'Top Products',
				path: 'reporting/top_products',
				icon: 'Medication',
				permission:[ROLEID.ADMIN,ROLEID.MANAGER]
			},
			top_prescription: {
				id: 'top_prescription',
				text: 'Top Prescription',
				path: 'reporting/top_prescription',
				icon: 'InsertDriveFile',
				permission:[ROLEID.ADMIN,ROLEID.MANAGER]
			},
			top_visit: {
				id: 'top_visit',
				text: 'Top Visit',
				path: 'reporting/top_visit',
				icon: 'MedicalServices',
				permission:[ROLEID.ADMIN,ROLEID.MANAGER]
			},
			overall_visit_report: {
				id: 'overall_visit_report',
				text: 'Overall Visit Report',
				path: 'reporting/overall-visit-report',
				icon: 'InsertDriveFile',
				permission:[ROLEID.ADMIN]
			},
			overall_mrd_report: {
				id: 'overall_mrd_report',
				text: 'Overall MRD Report',
				path: 'reporting/overall-mrd-report',
				icon: 'InsertDriveFile',
				permission:[ROLEID.ADMIN]
			},
		},
	},
};


export const chemistMenuGroupManagement = {
	chemistMenuGroupManagement: {
		id: 'chemistMenuGroupManagement',
		text: 'Management',
		permission:[ROLEID.ADMIN]
	},
	announcements: {
		id: 'announcement',
		text: 'Annoucements',
		path: '/announcements',
		icon: 'Campaign',
		subMenu: null,
		permission:[ROLEID.ADMIN]
	},
	leads: {
		id: 'leads',
		text: 'Leads',
		path: '/leads',
		icon: 'Leaderboard',
		subMenu: null,
		permission:[ROLEID.ADMIN]
	},
	targets: {
		id: 'targets',
		text: 'Targets',
		path: '/targets',
		icon: 'Adjust',
		subMenu: null,
		permission:[ROLEID.ADMIN]
	},
	specialProgram: {
		id: 'program',
		text: 'Special Program',
		path: '/special-program',
		icon: 'StarBorder',
		subMenu: null,
		permission:[ROLEID.ADMIN]
	},
};

export const chemistMenuGroupLogistics = {
	chemistMenuGroupLogistics: {
		id: 'chemistMenuGroupLogistics',
		text: 'Logistics',
		permission:[ROLEID.ADMIN],
	},
	deliveries: {
		id: 'deliveries',
		text: 'Deliveries',
		path: '/deliveries',
		icon: 'LocalShipping',
		subMenu: null,
		permission:[ROLEID.ADMIN],
	},
	requisitions: {
		id: 'requisitions',
		text: 'Requisitions',
		path: '/requisitions',
		icon: 'Assignment',
		subMenu: null,
		permission:[ROLEID.ADMIN],
	},
	inventory: {
		id: 'inventory',
		text: 'Inventory',
		path: '/inventory',
		icon: 'Inventory2',
		permission:[ROLEID.ADMIN],
		subMenu: {
			stock: {
				id: 'stock',
				text: 'Stock',
				path: 'inventory/stock',
				icon: 'Inventory',
			},
			transfers: {
				id: 'transfers',
				text: 'Transfers',
				path: 'inventory/transfers',
				icon: 'TransferWithinAStation',
			},
			minimum_quantities: {
				id: 'minimum_quantities',
				text: 'Minimum Quantities',
				path: 'inventory/minimum-quantities',
				icon: 'ProductionQuantityLimits',
			},
		},
	},
};

export const layoutPages = {
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
	},
	testPage: {
		id: 'LiveTV',
		text: 'LiveTV',
		path: '/live_tv',
		icon: 'LiveTv',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},

	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
	headerAndSubheader: {
		id: 'headerAndSubheader',
		text: 'Header & Subheader',
		path: 'page-layouts/header-and-subheader',
		icon: 'ViewAgenda',
	},
	onlyHeader: {
		id: 'onlyHeader',
		text: 'Only Header',
		path: 'page-layouts/only-header',
		icon: 'ViewStream',
	},
	onlySubheader: {
		id: 'onlySubheader',
		text: 'Only Subheader',
		path: 'page-layouts/only-subheader',
		icon: 'ViewStream',
	},
	onlyContent: {
		id: 'onlyContent',
		text: 'Only Content',
		path: 'page-layouts/only-content',
		icon: 'WebAsset',
	},
};

export default primaryChemistMenu;
