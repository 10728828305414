import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import useData from '../../hooks/useData';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../components/bootstrap/Modal';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import { baseUrl } from '../../helpers/helpers';
import Toasts from '../../components/bootstrap/Toasts';

export const UserAvatar = ({ srcSet, src }) => {
	return (
		<div className='user-avatar'>
			<img srcSet={srcSet} src={src} alt='Avatar' width={128} height={128} />
		</div>
	);
};
UserAvatar.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
};
UserAvatar.defaultProps = {
	srcSet: null,
};

const User = () => {
	const navigate = useNavigate();
	const { token, setToken, profile } = useData();
	const [resetModal, setResetModal] = useState(false);
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [resetData, setResetData] = useState({});
	const { addToast } = useToasts();
	const handleInput = (e, state, setState) => {
		setState({
			...state,
			[e.target.name]: e.target.value,
		});
	};
	const logOut = async () => {
		try {
			const res = await fetch(`${baseUrl.url}/api/logout`, {
				method: 'GET',
				credentials: 'include',
				headers: {
					Authorization: `bearer ${token}`,
				},
			});
			const data = await res.json();
			if (res.ok) {
				addToast(
					<Toasts
						title='Logging out'
						icon='Info'
						iconColor='success'
						time='Now'
						isDismiss>
						Successfully logged out
					</Toasts>,
					{
						autoDismiss: true,
					},
				);
				setTimeout(() => {
					setToken(null);
					navigate('/auth-pages/login');
				}, 3000);
			} else {
				// eslint-disable-next-line no-console
				console.log(data.error[0]);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};
	const handleResetPass = async (e) => {
		e.preventDefault();
		try {
			const res = await fetch(`${baseUrl.url}/api/reset_password`, {
				method: 'POST',
				headers: {
					Authorization: `bearer ${token}`,
				},
				body: JSON.stringify(resetData),
			});
			const data = await res.json();
			if (res.ok) {
				addToast(
					<Toasts
						title='Password Changed'
						icon='Info'
						iconColor='success'
						time='Now'
						isDismiss>
						Successfully password changed.Login now
					</Toasts>,
					{
						autoDismiss: true,
					},
				);
				setTimeout(() => {
					setToken(null);
					navigate('/auth-pages/login');
				}, 3000);
			} else {
				// eslint-disable-next-line no-console
				console.log(data.error[0]);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};
	return (
		<Dropdown>
			<Modal
				isOpen={resetModal}
				setIsOpen={() => setResetModal(false)}
				isStaticBackdrop
				isCentered
				size='sm'
				fullScreen='lg'
				isAnimation>
				<ModalHeader  setIsOpen={() => setResetModal(false)}>
					<ModalTitle id='usermodal'>Password Reset</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<form onSubmit={handleResetPass}>
						<div className='col-12'>
							<FormGroup id='login-username' isFloating label='Previous Password'>
								<Input
									autoComplete='username'
									value={resetData.previous_password}
									name='previous_password'
									type='text'
									onChange={(e) => handleInput(e, resetData, setResetData)}
								/>
							</FormGroup>
							<br />
							<FormGroup id='login-password' isFloating label='New Password'>
								<Input
									type='text'
									autoComplete='password'
									value={resetData.password}
									name='password'
									onChange={(e) => handleInput(e, resetData, setResetData)}
								/>
							</FormGroup>
							<br />
							<FormGroup id='login-password' isFloating label='Confirm Password'>
								<Input
									type='text'
									autoComplete='password'
									value={resetData.confirm_password}
									name='confirm_password'
									onChange={(e) => handleInput(e, resetData, setResetData)}
								/>
							</FormGroup>
						</div>
						<div className='col-12 mt-4'>
							<Button color='danger' type='submit' className='w-100 py-3'>
								Reset Now
							</Button>
						</div>
					</form>
				</ModalBody>
			</Modal>
			<DropdownToggle hasIcon={false}>
				<div className='user'>
					<UserAvatar
						srcSet=''
						src={`${baseUrl.url}/assets/images/user/${profile?.photo_url}`}
					/>
					<div className='user-info'>
						<div className='user-name'>{profile?.name}</div>
						<div className='user-sub-title'>{profile?.role}</div>
					</div>
				</div>
			</DropdownToggle>
			<DropdownMenu>
				<DropdownItem>
					<Button icon='AccountBox'>Profile</Button>
				</DropdownItem>
				<DropdownItem>
					<Button
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
						aria-label='Toggle fullscreen'>
						{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
					</Button>
				</DropdownItem>
				<DropdownItem isDivider />
				<DropdownItem>
					<Button icon='Loop' onClick={() => setResetModal(true)}>
						Reset Password
					</Button>
				</DropdownItem>
				<DropdownItem>
					<Button icon='Logout' onClick={logOut}>
						Logout
					</Button>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

export default User;
