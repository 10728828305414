import React, {useEffect, useState} from 'react';
import './LiveData.css'
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Icon from "../../components/icon/Icon";
import {baseUrl, dateFormatter} from "../../helpers/helpers";
import useData from "../../hooks/useData";
import Badge from "../../components/bootstrap/Badge";
import drugImage from '../../assets/drugs.png'
import location from '../../assets/map.png'

const LiveData = () => {
    const {token} = useData();
    const [overAllData, setOverallData] = useState({});
    const [depotData, setDepotData] = useState();
    const [products, setProducts] = useState();
    const [morningEveningData,setMorningEveningData] = useState([])

    const getExtraInfo = (id)=>{
        return morningEveningData?.depot_reporting_data?.find((item) => item?.depot_id === Number(id))
    }
    useEffect(() => {
        const getMorningEveningData = async () => {
            try {
                const url = `${baseUrl.url}/api/depot_reporting?from=${dateFormatter(
                    new Date()
                )}&to=${dateFormatter(new Date())}`;
                const res = await fetch(url, {
                    method: 'GET',
                    headers: {
                        Authorization: `bearer ${token}`,
                    },
                });

                if (res.ok && res.status === 204) {
                    setDepotData([]);
                } else {
                    const {data} = await res.json();
                    setMorningEveningData(data);
                }
            } catch (e) {
                console.log(e.message);
            }
        };
        getMorningEveningData().then();
        const intervalId2 = setInterval(getMorningEveningData, 60 * 1000);

        // Cleanup function to clear the interval
        return () => clearInterval(intervalId2);
    }, [token]);

    useEffect(() => {

        const getData = async () => {

            try {
                const url = `${baseUrl.url}/api/overall_depot_data?from=${dateFormatter(
                    new Date()
                )}&to=${dateFormatter(new Date())}`;
                const res = await fetch(url, {
                    method: 'GET',
                    headers: {
                        Authorization: `bearer ${token}`,
                    },
                });

                if (res.ok && res.status === 204) {
                    setOverallData([]);
                } else {
                    const resData = await res.json();
                    setOverallData(resData?.data?.overall);
                    setDepotData(resData?.data?.depot_data);
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e.message);
            }
        };
        getData().then();
        const intervalId = setInterval(getData, 60 * 1000);

        // Cleanup function to clear the interval
        return () => clearInterval(intervalId);
    }, [token,morningEveningData.length]);

    useEffect(() => {
        const getProducts = async () => {
            try {
                const url = `${baseUrl.url}/api/product_today_report?page=0&size=10`;
                const res = await fetch(url, {
                    method: 'GET',
                    headers: {
                        Authorization: `bearer ${token}`,
                    },
                });

                if (res.ok && res.status === 204) {
                    setProducts([]);
                } else {
                    const resData = await res.json();
                    setProducts(resData?.data?.items);
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e.message);
            }
        };
        getProducts().then();
        const intervalId2 = setInterval(getProducts, 60 * 1000);

        // Cleanup function to clear the interval
        return () => clearInterval(intervalId2);
    }, [token]);
    const checkColor = (num) => {
        if (num <= 30) {
            return 'danger'
        }
        if (num <= 60) {
            return 'warning'
        }
        return 'success'


    }
    const data = [
        {
            id: 1,
            count: overAllData?.total_active_employees,
            title: "Active Employee",
            icon: "PeopleAlt",
            bg: "teal"
        },
        {
            id: 2,
            count: overAllData?.total_prescription_data,
            title: "Prescription",
            icon: "InsertDriveFile",
            bg: "indigo"
        },
        {
            id: 3,
            count: overAllData?.total_doctor_visit_data,
            title: "Doctor Visit",
            icon: "MedicalServices",
            bg: "warning"
        },
        {
            id: 4,
            count: overAllData?.total_pharmacy_visit_data,
            title: "Pharmacy Visit",
            icon: "Store",
            bg: "blue"
        },
        {
            id: 5,
            count: morningEveningData?.overall?.morning_count,
            title: "Morning Report",
            icon: "WbSunny",
            bg: "violet"
        },
        {
            id: 6,
            count: morningEveningData?.overall?.evening_count,
            title: "Evening Report",
            icon: "NightlightRound",
            bg: "pink"
        },
    ]
    return (
        <div className='p-3 w-100'>
            <div className='row w-100 g-2'>
                {
                    data.map((dt) => {
                        return <div key={dt.id} className='col-6 col-xl-2'>
                            <div className={`p-4 rounded ${dt.bg} text-white shadow`}>
                                <div className='d-flex align-items-center justify-content-between'>
								<span className='font-2 fw-bold'>
									{dt.count}
								</span>
                                    <Icon icon={dt.icon} size='4x' color='white'/>
                                </div>
                                <h1 className='font-2 text-white'>{dt.title}</h1>
                            </div>
                        </div>
                    })
                }
            </div>
            <div className='mt-2 mb-md-0 mb-xxl-5'>
                <Carousel interval={5 * 1000} autoPlay infiniteLoop showIndicators={false}>
                    {
                        depotData?.map((d) => {
                            const monnData = getExtraInfo(d?.id)
                            return <div className='text-start'>
                                <div className="d-flex align-items-center">
                                    <img src={location} style={{width: '40px', height: '40px'}} alt=""/>
                                    <div className='ms-2'>
                                        <h1 className='font-0 text-black-100'>{d?.depot}</h1>
                                    </div>
                                </div>
                                <div className='row w-100 g-2'>
                                    <div className='col-6 col-xl-2'>
                                        <div className='p-4 rounded orange text-white shadow'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                    <span className='font-2 fw-bold'>
                                                        {d?.total_active_employees}
                                                    </span>
                                                <Icon icon='PeopleAlt' size='4x' color='white'/>
                                            </div>
                                            <h1 className='font-2 text-white'>Active Employee</h1>
                                        </div>
                                    </div>
                                    <div className='col-6 col-xl-2'>
                                        <div className='p-4 rounded pink text-white shadow'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                    <span className='font-2 fw-bold'>
                                                        {d?.total_prescription_data}
                                                    </span>
                                                <Icon icon='InsertDriveFile' size='4x' color='white'/>
                                            </div>
                                            <h1 className='font-2 text-white'>Prescription</h1>
                                        </div>
                                    </div>
                                    <div className='col-6 col-xl-2'>
                                        <div className='p-4 rounded violet text-white shadow'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                    <span className='font-2 fw-bold'>
                                                        {d?.total_doctor_visit_data}
                                                    </span>
                                                <Icon icon='MedicalServices' size='4x' color='white'/>
                                            </div>
                                            <h1 className='font-2 text-white'>Doctor Visit</h1>
                                        </div>
                                    </div>
                                    <div className='col-6 col-xl-2'>
                                        <div className='p-4 rounded cyan text-white shadow'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                    <span className='font-2 fw-bold'>
                                                        {d?.total_pharmacy_visit_data}
                                                    </span>
                                                <Icon icon='Store' size='4x' color='white'/>
                                            </div>
                                            <h1 className='font-2 text-white'>Pharmacy Visit</h1>
                                        </div>
                                    </div>
                                    <div className='col-6 col-xl-2'>
                                        <div className='p-4 rounded warning text-white shadow'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                    <span className='font-2 fw-bold'>
                                                        {monnData?.morning_count}
                                                    </span>
                                                <Icon icon='WbSunny' size='4x' color='white'/>
                                            </div>
                                            <h1 className='font-2 text-white'>Morning Report</h1>
                                        </div>
                                    </div>
                                    <div className='col-6 col-xl-2'>
                                        <div className='p-4 rounded violet text-white shadow'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                    <span className='font-2 fw-bold'>
                                                        {monnData?.evening_count}
                                                    </span>
                                                <Icon icon='NightlightRound' size='4x' color='white'/>
                                            </div>
                                            <h1 className='font-2 text-white'>Evening Report</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </Carousel>
            </div>
            <hr/>
            <div>
                <table className='w-100 custom-table'>
                    <thead>
                    <tr>
                        <th scope='col'>Product Name</th>
                        <th scope='col' className='text-center cursor-pointer'>Type</th>
                        <th scope='col' className='cursor-pointer text-center'>Target Achieved</th>
                        <th scope='col' className='cursor-pointer text-center'>Prescription Target</th>
                        <th scope='col' className='cursor-pointer text-center'>Prescription Count</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        products?.map((p) => {
                            const percentage = (Number(p?.today_prescription_count) / Number(p?.target)) * 100;
                            const totalPercentage = percentage?.toFixed(0);

                            return <tr key={p?.product_name}>
                                <td className='font-2'>
                                    <img src={drugImage} className='iconic-image' alt="drug"/>
                                    <span className='ms-4 text-success'>{p?.product_name}</span></td>
                                <td className='font-2 text-center'>
                                    <Badge color='warning' style={{width: '130px'}}>
                                        {p?.category}
                                    </Badge>
                                </td>
                                <td className='font-2 text-center'>
                                    <Badge color={checkColor(totalPercentage)} style={{width: '100px'}}>
                                        {percentage?.toFixed(0)}%
                                    </Badge>
                                </td>
                                <td className='font-2 text-center text-success fw-bold'>
                                    <Badge  color='info' style={{width: '100px'}}>
                                        {p?.target}
                                    </Badge>
                                </td>
                                <td className='font-2 text-center text-success fw-bold'>
                                    <Badge  color='success' style={{width: '100px'}}>
                                        {p?.today_prescription_count}
                                    </Badge>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default LiveData;