import React, { createContext } from 'react';
import useStore from '../hooks/useStore';

export const MyContext = createContext();
const ProviderContext = ({ children }) => {
	const data = useStore();
	return <MyContext.Provider value={data}>{children}</MyContext.Provider>;
};

export default ProviderContext;
