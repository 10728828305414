import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useData from '../hooks/useData';
import Loader from '../components/Loader/Loader';


const PrivateRoute = ({ children }) => {
	const { token, loader,profile } = useData();
	const location = useLocation();
	const currentPath = location.pathname.slice(1,100) || '/';
	const hasPermission = profile?.permission?.indexOf(currentPath) !== -1;

	if (loader) {
		return <Loader />;
	}
	if (!token) {
		return <Navigate to='/auth-pages/login' state={{ from: location }} />;
	}
	if (profile.permission!=='All' && !hasPermission) {
		return <Navigate to='/unauthorized' state={{ from: location }} />;
	}
	return children;
};

export default PrivateRoute;
