import { useEffect, useState } from 'react';
import { baseUrl } from '../helpers/helpers';
import {chemistMenuGroupOverview} from "../menu";

const useStore = () => {
	const [loader, setLoader] = useState(true);
	const [token, setToken] = useState(null);
	const [profile, setProfile] = useState({});


	useEffect(() => {
		const refresh = async () => {
			try {
				const res = await fetch(`${baseUrl.url}/auth/refresh`, {
					method: 'GET',
					credentials: 'include',
				});
				const resData = await res.json();
				if (res.ok) {
					setToken(resData.access_token);
					setLoader(false);
				}
				setLoader(false);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		};
		if (!token) {
			refresh().then();
		}

		const interval = setInterval(refresh, 14 * 60 * 1000);
		return () => clearInterval(interval);
	}, [setLoader, token]);

	useEffect(() => {
		if (!token) {
			return;
		}
		const ROLES = [
			{
				ID:1,
				ROLE:'Admin',
				PERMISSION:'All'
			},
			{
				ID: 2,
				ROLE: 'Manager',
				PERMISSION:[
					chemistMenuGroupOverview.dashboard.path,
					chemistMenuGroupOverview.data.subMenu.prescriptions.path,
					chemistMenuGroupOverview.data.subMenu.doctor_visit.path,
					chemistMenuGroupOverview.data.subMenu.entity.path,
					chemistMenuGroupOverview.data.subMenu.entity.path,
					chemistMenuGroupOverview.reporting.subMenu.prescription_target.path,
					chemistMenuGroupOverview.reporting.subMenu.visit_target.path,
					chemistMenuGroupOverview.reporting.subMenu.top_products.path,
					chemistMenuGroupOverview.reporting.subMenu.top_prescription.path,
					chemistMenuGroupOverview.reporting.subMenu.top_visit.path,
				]
			}
		]
		const getProfile = async () => {
			try {
				const res = await fetch(`${baseUrl.url}/api/current_user`, {
					method: 'GET',
					headers: {
						Authorization: `bearer ${token}`,
					},
				});
				const data = await res.json();
				if (res.ok) {
					const roleData = ROLES?.find(role => role?.ROLE === data?.data?.role);
					setProfile({
						...data?.data,
						permission:roleData?.PERMISSION
					});
				} else {
					// eslint-disable-next-line no-console
					console.log(data.error[0]);
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			}
		};
		getProfile().then();
		// eslint-disable-next-line no-console
	}, [token, setProfile]);
	return {
		loader,
		setLoader,
		token,
		setToken,
		profile,
	};
};

export default useStore;
