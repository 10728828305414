const LANG = {
	EN: {
		text: 'English',
		lng: 'en-US',
		icon: 'CustomUsa',
	},
	BD: {
		text: 'বাংলা',
		lng: 'bn',
		icon: 'CustomBangladesh',
	},
};

export const getLangWithKey = (key) => {
	return LANG[Object.keys(LANG).filter((f) => LANG[f].lng === key)];
};

export default LANG;
