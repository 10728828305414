export function test() {
	return null;
}
export const dateFormatter = (d) => {
	const date = new Date(d);

	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
	const day = date.getDate().toString().padStart(2, '0');
	return `${year}-${month}-${day}`;
};
export const convertTimeDate = (inputDate) => {
	// Parse input date string as a Date object
	const date = new Date(inputDate);

	// Extract date components
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');

	return `${year}-${month}-${day} ${hours}:${minutes}:00`;
};
export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs) => {
	return (value) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'BDT',
		currencyDisplay: 'narrowSymbol',
	});
};

export const average = (array) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1, value2) => ((value1 / value2 - 1) * 100).toFixed(2);

export const getFirstLetter = (text, letterCount = 2) =>
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func, wait = 1000) => {
	let timeout;

	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export const formatAddress = (location) => {
	let formattedAddress = '';
	formattedAddress += location.address ? `${location.address}, ` : '';
	formattedAddress += location.postcode.postOffice ? `${location.postcode.postOffice}, ` : '';
	formattedAddress += location.upazila.name ? `${location.upazila.name}, ` : '';
	formattedAddress += location.district.name ? `${location.district.name}, ` : '';
	formattedAddress += location.division.name ? `${location.division.name}` : '';
	return formattedAddress;
};
export const baseUrl = {
	url: 'https://apiforce.chemistbd.com',
};
export const distance = (lat1, lon1, lat2, lon2, unit) => {
	const radlat1 = (Math.PI * lat1) / 180;
	const radlat2 = (Math.PI * lat2) / 180;
	const theta = lon1 - lon2;
	const radtheta = (Math.PI * theta) / 180;
	let dist =
		Math.sin(radlat1) * Math.sin(radlat2) +
		Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
	dist = Math.acos(dist);
	dist = (dist * 180) / Math.PI;
	dist = dist * 60 * 1.1515;
	if (unit === 'K') {
		dist *= 1.609344;
	}
	if (unit === 'M') {
		dist *= 0.8684;
	}
	return dist;
};
export const mapOption = {
	maxZoom: 20,
	minZoom: 3,
	mapTypeId: 'roadmap',
	mapTypeControl: true,
	scaleControl: true,
	streetViewControl: true,
	rotateControl: true,
	fullscreenControl: true,
	zoomControl: true,
	styles: [
		{
			featureType: 'water',
			elementType: 'geometry',
			stylers: [
				{
					color: '#e9e9e9',
				},
				{
					lightness: 17,
				},
			],
		},
		{
			featureType: 'landscape',
			elementType: 'geometry',
			stylers: [
				{
					color: '#f5f5f5',
				},
				{
					lightness: 20,
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#ffffff',
				},
				{
					lightness: 17,
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#ffffff',
				},
				{
					lightness: 29,
				},
				{
					weight: 0.2,
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'geometry',
			stylers: [
				{
					color: '#ffffff',
				},
				{
					lightness: 18,
				},
			],
		},
		{
			featureType: 'road.local',
			elementType: 'geometry',
			stylers: [
				{
					color: '#ffffff',
				},
				{
					lightness: 16,
				},
			],
		},
		{
			featureType: 'poi',
			elementType: 'geometry',
			stylers: [
				{
					color: '#f5f5f5',
				},
				{
					lightness: 21,
				},
			],
		},
		{
			featureType: 'poi.park',
			elementType: 'geometry',
			stylers: [
				{
					color: '#dedede',
				},
				{
					lightness: 21,
				},
			],
		},
		{
			elementType: 'labels.text.stroke',
			stylers: [
				{
					visibility: 'on',
				},
				{
					color: '#ffffff',
				},
				{
					lightness: 16,
				},
			],
		},
		{
			elementType: 'labels.text.fill',
			stylers: [
				{
					saturation: 36,
				},
				{
					color: '#333333',
				},
				{
					lightness: 40,
				},
			],
		},
		{
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'transit',
			elementType: 'geometry',
			stylers: [
				{
					color: '#f2f2f2',
				},
				{
					lightness: 19,
				},
			],
		},
		{
			featureType: 'administrative',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#fefefe',
				},
				{
					lightness: 20,
				},
			],
		},
		{
			featureType: 'administrative',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#fefefe',
				},
				{
					lightness: 17,
				},
				{
					weight: 1.2,
				},
			],
		},
	],
};

export const colorByDistance = (data) => {
	const d = distance(
		data?.latitude,
		data?.longitude,
		data?.entity?.latitude,
		data?.entity?.longitude,
		'K',
	).toFixed(2);
	if (d > 0.2) {
		return 'danger';
	}
	return 'success';
};
export const datePair = () => {
	const today = new Date();
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
	const day = String(today.getDate()).padStart(2, '0');

	const oneDayAgo = new Date(today);
	oneDayAgo.setDate(today.getDate() - 1);

	const year4 = oneDayAgo.getFullYear();
	const month4 = String(oneDayAgo.getMonth() + 1).padStart(2, '0');
	const day4 = String(oneDayAgo.getDate()).padStart(2, '0');

	const lastWeek = new Date(today);
	lastWeek.setDate(today.getDate() - 7);
	const year3 = lastWeek.getFullYear();
	const month3 = String(lastWeek.getMonth() + 1).padStart(2, '0');
	const day3 = String(lastWeek.getDate()).padStart(2, '0');

	const earlierDate = new Date(today);
	earlierDate.setDate(today.getDate() - 30);
	const year2 = earlierDate.getFullYear();
	const month2 = String(earlierDate.getMonth() + 1).padStart(2, '0');
	const day2 = String(earlierDate.getDate()).padStart(2, '0');
	const formattedOneDayAgo = `${year4}-${month4}-${day4}`;
	const todaysDate = `${year}-${month}-${day}`;
	const formattedLastWeek = `${year3}-${month3}-${day3}`;
	const earlier30Date = `${year2}-${month2}-${day2}`;
	return {
		todaysDate,
		formattedLastWeek,
		earlier30Date,
		formattedOneDayAgo,
	};
};

export const dateDifference = (date1,date2)=>{
	const firstDate = new Date(date1);
	const secondDate = new Date(date2);

// Calculate the difference in milliseconds
	const timeDifference = secondDate - firstDate; // This gives difference in milliseconds

// Convert the time difference to days
	return timeDifference / (1000 * 60 * 60 * 24)
}
