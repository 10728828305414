import React from 'react';
import PropTypes from 'prop-types';
import chemistLogo from '../assets/logos/logo-chemist-white.png';

const Logo = ({ width, height }) => {
	return (
		<img
			height={width !== 2155 ? width * (854 / 2155) : height}
			width={height !== 854 ? height * (2155 / 854) : width}
			alt='Chemist Logo'
			src={chemistLogo}
		/>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
