import classNames from 'classnames';
import React from 'react';
import moment from 'moment';
import useDarkMode from '../../../hooks/useDarkMode';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';

const ChemistHeader = () => {
	const { darkModeStatus } = useDarkMode();
	return (
		<Header>
			<HeaderLeft>
				<div
					className={classNames('fs-3', {
						'text-dark': !darkModeStatus,
					})}>
					{moment().format('dddd, MMM D, YYYY')}
				</div>
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default ChemistHeader;
