import React from 'react';
import './Loader.css';

const Loader = () => {
	return (
		<div
			style={{ height: '100vh', zIndex: 50 }}
			className='position-fixed top-0 start-0 bg-light w-100 d-flex align-items-center justify-content-center'>
			<div className='spinner' />
		</div>
	);
};

export default Loader;
