import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import useData from '../../../hooks/useData';
import { baseUrl } from '../../../helpers/helpers';
import Icon from '../../../components/icon/Icon';

// eslint-disable-next-line react/prop-types
const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-2'>Create Account,</div>
				<div className='text-center h4 text-muted mb-2'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-2'>Welcome</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

const Login = () => {
	const { setToken, setLoader } = useData();
	const { darkModeStatus } = useDarkMode();
	const [loginLoader, setLoginLoader] = useState(false);
	const [errors, setErrors] = useState({});
	// const [isNewUser, setIsNewUser] = useState(isSignUp);
	const [passwordType, setPasswordType] = useState(true);
	const navigate = useNavigate();
	const [loginData, setLoginData] = useState({});
	const handleInputChange = (e, state, setState) => {
		setState({
			...state,
			[e.target.name]: e.target.value,
		});
	};
	const handleOnClick = async () => {
		setErrors({ err: null });
		setLoginLoader(true);
		try {
			const res = await fetch(`${baseUrl.url}/auth/authenticate`, {
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify(loginData),
			});
			const data = await res.json();
			if (res.ok) {
				setToken(data.access_token);
				navigate('/');
			} else {
				setErrors({ err: data.error[0] });
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoader(false);
			setLoginLoader(false);
		}
	};
	return (
		<PageWrapper
			// title={isNewUser ? 'Sign Up' : 'Login'}
			// className={classNames({ 'bg-warning': !isNewUser, 'bg-info': !!isNewUser })}
			title='Login'
			className='bg-warning'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<div
											style={{ background: 'green' }}
											className='p-2 py-3 rounded-3'>
											<Logo width={200} />
										</div>
									</Link>
								</div>

								<LoginHeader isNewUser={false} />

								<form className='row g-4'>
									<form>
										<div className='col-12'>
											<FormGroup
												id='login-username'
												isFloating
												label='Your email or username'>
												<Input
													autoComplete='username'
													value={loginData.username}
													name='username'
													type='email'
													onChange={(e) =>
														handleInputChange(
															e,
															loginData,
															setLoginData,
														)
													}
												/>
											</FormGroup>
											<br />
											<div className='position-relative'>
												<FormGroup
													id='login-password'
													isFloating
													label='Password'>
													<Input
														type={passwordType ? 'password' : 'text'}
														autoComplete='password'
														value={loginData.password}
														name='password'
														onChange={(e) =>
															handleInputChange(
																e,
																loginData,
																setLoginData,
															)
														}
													/>
												</FormGroup>
												<span
													style={{ top: '16px', right: '20px' }}
													className='position-absolute'>
													{passwordType ? (
														<Icon
															style={{ cursor: 'pointer' }}
															onClick={() =>
																setPasswordType(!passwordType)
															}
															color='primary'
															size='lg'
															icon='RemoveRedEye'
														/>
													) : (
														<Icon
															style={{ cursor: 'pointer' }}
															onClick={() =>
																setPasswordType(!passwordType)
															}
															color='primary'
															size='lg'
															icon='Lock'
														/>
													)}
												</span>
											</div>
										</div>
										{errors.err !== null && (
											<p className='my-2 text-center text-danger'>
												{errors.err}
											</p>
										)}
										<div className='col-12 mt-3'>
											<Button
												color='warning'
												className='w-100 py-3'
												type='submit'
												isDisable={loginLoader}
												onClick={handleOnClick}>
												Login
											</Button>
										</div>
									</form>
									{/* )} */}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<Link to='/' className='link-dark'>
								Privacy policy
							</Link>
							<Link to='/' className='link-dark ms-2'>
								Terms of use
							</Link>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Login;
